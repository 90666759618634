import React, { useEffect, useState } from "react";
import { Filters, Page, Title } from "../../components/common";
import { useSelector, useDispatch } from "react-redux";
import { selectNodes, resetChangedNodeId } from "./nodeSlice";
import NewNodeCard from "./NewNodeCard";
import {
  faCalendarCircleUser,
  faTachometerAltAverage,
} from "@fortawesome/pro-light-svg-icons";
import TaskDrawer from "./task/TaskDrawer";
import "./nodespage.css";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import TaskFilters from "./task/TaskFilters";
import TasksPageDefault from "./task/TasksPageDefault";
import TasksPageComplete from "./task/TasksPageComplete";
import MeetingsPageDefault from "./meeting/MeetingsPageDefault";
import { Navigation } from "../../components/common";
import MeetingFilters from "./meeting/MeetingFilters";

dayjs.extend(isSameOrBefore);

const NodesPage = () => {
  const { taskId } = useParams();
  const [projectId, changeProjectId] = useState(false);

  const tasks = useSelector(selectNodes);
  const dispatch = useDispatch();
  const [typeFilter, changeTypeFilter] = useState("task");
  const [statusFilter, changeStatusFilter] = useState("incomplete");
  const [dateFilter, changeDateFilter] = useState(new Date());

  useEffect(() => {
    return () => {
      dispatch(resetChangedNodeId());
    };
  }, [dispatch]);
  return (
    <Page
      drawer={<TaskDrawer taskId={taskId} key={taskId} />}
      drawerIsVisible={taskId}
      source="/tasks"
    >
      <Title icon={faCalendarCircleUser}>Day</Title>
      <NewNodeCard />
      <Filters>
        <div>
          <Navigation
            text="Tasks"
            active={typeFilter === "task"}
            onClick={() => changeTypeFilter("task")}
            count={
              tasks.filter(
                ({ type, dateTodo }) =>
                  type === "task" &&
                  dayjs(dateTodo).isSameOrBefore(dayjs(), "day")
              ).length
            }
          />
          <Navigation
            text="Meetings"
            active={typeFilter === "meeting"}
            onClick={() => changeTypeFilter("meeting")}
          />
        </div>
        {typeFilter === "task" && (
          <TaskFilters
            status={statusFilter}
            changeStatus={changeStatusFilter}
          />
        )}
        {typeFilter === "meeting" && (
          <MeetingFilters
            dateFilter={dateFilter}
            changeDateFilter={changeDateFilter}
          />
        )}
      </Filters>

      {typeFilter === "task" &&
        (statusFilter === "incomplete" ? (
          <TasksPageDefault type={typeFilter} />
        ) : (
          <TasksPageComplete />
        ))}
      {typeFilter === "meeting" && <MeetingsPageDefault date={dateFilter} />}
    </Page>
  );
};

export default NodesPage;
